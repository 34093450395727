import type { Village, Meta, Objects } from '~/types'
import { FilterTabType } from '~/enums'

interface State {
  items: Village[]
  meta: Meta
  count: number
  pending: boolean
  pendingList: boolean
  pendingMap: boolean
  itemsLoadedServer: boolean
  itemsLoadedClient: boolean
}

const makeTags = (params: any, fields: any) => {
  const tags = []

  for (const field of fields) {
    if (field.length > 0) {
      tags.push(field.map((t: any) => t.id))
    }
  }

  return tags
}

const makeQuery = (params: any) => {
  const filterStore = useFilterStore()

  const query = {} as any

  const tags = makeTags(params, [
    params.types,
    params.highway,
    params.purpose_lands,
  ])
  if (tags.length > 0) {
    query['filter[tags]'] = tags.join(',')
  }

  const tagsStrict = makeTags(params, [
    params.communications,
    params.heating,
    params.safety,
    params.infrastructures,
    params.transports,
    params.village_nears,
  ])
  if (tagsStrict.length > 0) {
    query['filter[tags_strict]'] = tagsStrict.join(',')
  }

  const tagsTypes = makeTags(params, [
    params.types,
  ])
  if (tagsTypes.length > 0) {
    query['filter[tags_types]'] = tags.join(',')
  }

  if (params.class?.length > 0) {
    query['filter[class]'] = params.class.map((t: any) => t.id).join(',')
  }

  if (params.roads?.length > 0) {
    query['filter[roads]'] = params.roads.map((t: any) => t.id).join(',')
  }

  if (params.village_nears?.length > 0) {
    query['filter[village_nears]'] = params.village_nears.map((t: any) => t.id).join(',')
  }

  if (params.mkad.from) {
    query['filter[mkad][from]'] = params.mkad.from
  }
  if (params.mkad.to) {
    query['filter[mkad][to]'] = params.mkad.to
  }

  if (params.area.plot.from) {
    query['filter[plot_area][from]'] = params.area.plot.from
  }

  if (params.area.plot.to) {
    query['filter[plot_area][to]'] = params.area.plot.to
  }

  if (params.area.house.from) {
    query['filter[house_area][from]'] = params.area.house.from
  }

  if (params.area.house.to) {
    query['filter[house_area][to]'] = params.area.house.to
  }

  if (params.price.from) {
    query['filter[price][from]'] = params.price.from
  }

  if (params.price.to) {
    query['filter[price][to]'] = params.price.to
  }

  if (params.search) {
    query['filter[search]'] = params.search;
  }

  if (parseInt(params.page) > 1) {
    query['page'] = params.page
  }

  if (filterStore.tab === FilterTabType.Map) {
    query['map'] = true
  }

  query['perPage'] = 20

  return query
}

export const useVillageStore = defineStore('village', {
  state: (): State => {
    return <State>{
      items: [] as Village[],
      meta: {} as Meta,
      count: 0,
      pending: false,
      pendingList: false,
      pendingMap: false,
      itemsLoadedServer: false,
      itemsLoadedClient: false
    }
  },
  getters: {
    itemsLoaded: (state: State) => {
      return state.itemsLoadedServer && state.itemsLoadedClient
    },
    itemsCount: (state: State) => {
      return state.items.length
    }
  },
  actions: {
    list(params: any) {
      const query = makeQuery(params)

      return useAPIFetch<{ data: Village[], meta: Meta }>('/v1/villages/search', {
        query
      })
    },
    slug(slug: string) {
      return useAPIFetch<Objects>('/v1/villages/slug/' + slug, {
        method: 'GET',
      })
    },
    getQuery(params: any) {
      return makeQuery(params)
    },
    async search(params: any){
      this.pendingList = true

      const query = makeQuery(params)

      const result = await useAPIFetch<{ data: Village[], meta: Meta }>('/v1/villages/search', {
        query
      })

      this.pendingList = false

      this.items = result.data
      this.meta = result.meta

      import.meta.server
        ? this.itemsLoadedServer = true
        : this.itemsLoadedClient = true
    },
    async searchMap(params: any){
      this.pendingMap = true

      const query = makeQuery(params)

      const result = await useAPIFetch<{ data: Village[], meta: Meta }>('/v1/villages/search/map', {
        query
      })

      this.pendingMap = false

      this.items = result.data
      this.meta = result.meta
    },
    async searchCount(params: any){
      this.count = await this.getSearchCount(params)
    },
    async getSearchCount(params: any){
      this.pending = true
      
      const query = makeQuery(params)
      
      const result = await useAPIFetch<{ count: number }>('/v1/villages/search/count', {
        query
      })
      
      this.pending = false
      
      return result.count
    },
    async sendFeedback(id: number, data: any) {
      return useAPI('/v1/villages/' + id + '/feedback', {
        method: 'POST',
        body: data,
      })
    },
    async sendRequest(data: any) {
      return useAPI<Village>('/v1/villages/request', {
        method: 'POST',
        body: data,
      })
    }
  }
})
